<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header title="Statuses General">
                    <template v-slot:description>
                        <div>
                            <div>import { HbStatusGeneral } from 'hummingbird-aviary';</div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card no-title class="mt-4 mb-6">
            <div>
                <v-row no-gutters class="py-3 px-6">

                    <v-col cols="3" class="ma-0 pa-0 hb-font-header-3-medium" align="center">
                        Icon + Text
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 hb-font-header-3-medium" align="center">
                        Icon + Text + With Tooltips
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 hb-font-header-3-medium" align="center">
                        Icon Only
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 hb-font-header-3-medium" align="center">
                        Icon Only + With Tooltips
                    </v-col>
                </v-row>

                <v-divider></v-divider>

                <v-row no-gutters class="pt-6 pb-3 px-6">

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral status="default" />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            status="default"
                            tooltip-header="Tooltip Header"
                            tooltip-body="Tooltip Body"
                        />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            tooltip-header="Tooltip Header"
                            tooltip-body="Tooltip Body"
                        />
                    </v-col>

                </v-row>

                <v-row no-gutters class="py-3 px-6">

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="error"
                            status="error"
                        />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="error"
                            status="error"
                            tooltip-header="Tooltip Header"
                            tooltip-body="Tooltip Body"
                        />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral type="error" />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="error"
                            tooltip-header="Tooltip Header"
                            tooltip-body="Tooltip Body"
                        />
                    </v-col>

                </v-row>

                <v-row no-gutters class="py-3 px-6">

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="caution"
                            status="caution"
                        />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="caution"
                            status="caution"
                            tooltip-header="Tooltip Header"
                            tooltip-body="Tooltip Body"
                        />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral type="caution" />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="caution"
                            tooltip-header="Tooltip Header"
                            tooltip-body="Tooltip Body"
                        />
                    </v-col>

                </v-row>

                <v-row no-gutters class="py-3 px-6">

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="success"
                            status="success"
                        />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="success"
                            status="success"
                            tooltip-header="Tooltip Header"
                            tooltip-body="Tooltip Body"
                        />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral type="success" />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="success"
                            tooltip-header="Tooltip Header"
                            tooltip-body="Tooltip Body"
                        />
                    </v-col>

                </v-row>

                <v-row no-gutters class="pt-3 pb-6 px-6">

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="guidance"
                            status="guidance"
                        />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="guidance"
                            status="guidance"
                            tooltip-header="Tooltip Header"
                            tooltip-body="Tooltip Body"
                        />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral type="guidance" />
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="3" class="ma-0 pa-0 d-flex justify-center">
                        <HbStatusGeneral
                            type="guidance"
                            tooltip-header="Tooltip Header"
                            tooltip-body="Tooltip Body"
                        />
                    </v-col>

                </v-row>

            </div>

            <hb-card class="pa-6" color="#E0F5F5" no-title>
<pre>
&lt;HbStatusGeneral status="default" />

&lt;HbStatusGeneral
    status="default"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>

&lt;HbStatusGeneral />

&lt;HbStatusGeneral
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>

&lt;HbStatusGeneral
    type="caution"
    status="caution"
/>

&lt;HbStatusGeneral
    type="caution"
    status="caution"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>

&lt;HbStatusGeneral type="caution" />

&lt;HbStatusGeneral
    type="caution"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>

&lt;HbStatusGeneral
    type="error"
    status="error"
/>

&lt;HbStatusGeneral
    type="error"
    status="error"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>

&lt;HbStatusGeneral type="error" />

&lt;HbStatusGeneral
    type="error"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>

&lt;HbStatusGeneral
    type="success"
    status="success"
/>

&lt;HbStatusGeneral
    type="success"
    status="success"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>

&lt;HbStatusGeneral type="success" />

&lt;HbStatusGeneral
    type="success"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>

&lt;HbStatusGeneral
    type="guidance"
    status="guidance"
/>

&lt;HbStatusGeneral
    type="guidance"
    status="guidance"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>

&lt;HbStatusGeneral type="guidance" />

&lt;HbStatusGeneral
    type="guidance"
    tooltip-header="Tooltip Header"
    tooltip-body="Tooltip Body"
/>
</pre>

            </hb-card>
        </hb-card>

        <hb-card title="Default Statuses General" class="mt-4 mb-6">
            <hb-data-table
                :headers="headers"
                :items="itemsDefault"
                class="pa-6"
            >
                <template v-slot:item.chip="{ item }">
                    <HbStatusGeneral :status="item.name" />
                </template>

                <template v-slot:item.code="{ item }">
                    <hb-btn color="primary" :small="true" @click="grabCode(item.name)">Grab Code</hb-btn>
                    <div :id="item.name" style="position: absolute;z-index:-999999;top:0;left:0;">&lt;HbStatusGeneral status="{{ item.name }}" /></div>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="Invoice Statuses General" class="mt-4 mb-6">
            <hb-data-table
                :headers="headers"
                :items="itemsInvoices"
                class="pa-6"
            >
                <template v-slot:item.chip="{ item }">
                    <HbStatusGeneral :status="item.name" />
                </template>

                <template v-slot:item.code="{ item }">
                    <hb-btn color="primary" :small="true" @click="grabCode(item.name)">Grab Code</hb-btn>
                    <div :id="item.name" style="position: absolute;z-index:-999999;top:0;left:0;">&lt;HbStatusGeneral status="{{ item.name }}" /></div>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="Document Statuses General" class="mt-4 mb-6">
            <hb-data-table
                :headers="headers"
                :items="itemsDocuments"
                class="pa-6"
            >
                <template v-slot:item.chip="{ item }">
                    <HbStatusGeneral :status="item.name" />
                </template>

                <template v-slot:item.code="{ item }">
                    <hb-btn color="primary" :small="true" @click="grabCode(item.name)">Grab Code</hb-btn>
                    <div :id="item.name" style="position: absolute;z-index:-999999;top:0;left:0;">&lt;HbStatusGeneral status="{{ item.name }}" /></div>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="Gates Statuses General" class="mt-4 mb-6">
            <hb-data-table
                :headers="headers"
                :items="itemsGates"
                class="pa-6"
            >
                <template v-slot:item.chip="{ item }">
                    <HbStatusGeneral :status="item.name" />
                </template>

                <template v-slot:item.code="{ item }">
                    <hb-btn color="primary" :small="true" @click="grabCode(item.name)">Grab Code</hb-btn>
                    <div :id="item.name" style="position: absolute;z-index:-999999;top:0;left:0;">&lt;HbStatusGeneral status="{{ item.name }}" /></div>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="Misc Statuses General" class="mt-4 mb-6">
            <hb-data-table
                :headers="headers"
                :items="itemsMisc"
                class="pa-6"
            >
                <template v-slot:item.chip="{ item }">
                    <HbStatusGeneral :status="item.name" />
                </template>

                <template v-slot:item.code="{ item }">
                    <hb-btn color="primary" :small="true" @click="grabCode(item.name)">Grab Code</hb-btn>
                    <div :id="item.name" style="position: absolute;z-index:-999999;top:0;left:0;">&lt;HbStatusGeneral status="{{ item.name }}" /></div>
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="Slots" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="snackbarItem + ' <hb-general-status> component code copied to clipboard.'"
        >
        </hb-global-notification>
        
    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemStatusesGeneral",
        data: function() {
            return {
                snackbarItem: '',
                success: false,
                headers: [
                    { text: 'Chip', value: 'chip' },
                    { text: 'Grab Code', value: 'code' }
                ],
                itemsDefault: [
                    { icon_color: '#637381', name: 'Default', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-default' },
                ],
                itemsInvoices: [
                    { icon_color: '#637381', name: 'Void', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-default' },
                    { icon_color: '#637381', name: 'Write Off', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-default' },
                    { icon_color: '#FB4C4C', name: 'Past Due', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FFD600', name: 'Open', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Paid', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                ],
                itemsDocuments: [
                    { icon_color: '#FB4C4C', name: 'Bounced', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Error', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Failed', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FFD600', name: 'In Progress', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Print & Mail', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Ready to Sign', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Sent', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Signing in Progress', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Completed', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Delivered', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Opened', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Signed', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Success', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Generated', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Resolved', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Retried', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Uploaded', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                ],
                itemsGates: [
                    { icon_color: '#02AD0F', name: 'Active', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Suspended', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Access Alert', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FB4C4C', name: 'Manual Suspension', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Delinquency Suspension', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                ],
                itemsMisc: [
                    { icon_color: '#637381', name: 'Deactivated',icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-default' },
                    { icon_color: '#637381', name: 'Deleted', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-default' },
                    { icon_color: '#637381', name: 'Offline', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-default' },
                    { icon_color: '#637381', name: 'Lease Closed', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-default' },
                    { icon_color: '#637381', name: 'Retired Lead', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-default' },
                    { icon_color: '#637381', name: 'Retired', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-default' },
                    { icon_color: '#FB4C4C', name: 'Active Lien', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Auction', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Bankruptcy', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Overlocked', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Remove Overlock', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Schedule for Auction', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'Scheduled for Auction', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FB4C4C', name: 'To Overlock', icon_mdi_code: 'mdi-alert', status_color: 'hb-status-general-text-error' },
                    { icon_color: '#FFD600', name: 'Active Lead', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Delinquent', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Future Leased', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Not Configured', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'On-Hold', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Pending', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#FFD600', name: 'Reserved', icon_mdi_code: 'mdi-alert-octagon', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Available', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Converted', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Converted Lead', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#02AD0F', name: 'Current', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Charity', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Company', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                    { icon_color: '#3D8FFF', name: 'Leased', icon_mdi_code: 'mdi-check-circle', status_color: 'hb-status-general-text-night' },
                ],
                propHeaders: [
                    { text: 'Name', value: 'name', width: '110' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'type', type: 'string', default: 'undefined', description: 'Sets the color scheme for the icon and text. The available options are "default", "error", "caution", "success", and "guidance".' },
                    { name: 'status', type: 'string', default: 'undefined', description: 'Sets the status text.' },
                    { name: 'icon', type: 'string', default: 'undefined', description: 'Changes the default icon. Use "mdi-xxxxxxxxx" code syntax.' },
                    { name: 'tooltip-header', type: 'string', default: 'undefined', required: 'no',  description: 'Sets the tooltip hover-over header text. You can also use the "v-slot:tooltipHeader" slot to set the tooltip header content (useful for when you need to set more than just a basic string, and need to set custom html.' },
                    { name: 'tooltip-body', type: 'string', default: 'undefined', required: 'no',  description: 'Sets the tooltip hover-over content text.  You can also use the "v-slot:tooltipBody" slot to set the tooltip body content (useful for when you need to set more than just a basic string, and need to set custom html.' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'tooltipHeader', description: 'This slot is where you can set the tooltip header content.' },
                    { name: 'tooltipBody', description: 'This slot is where you can set the tooltip body content.' },
                ],
            };
        },
        methods: {
            grabCode(code){
                this.snackbarItem = code;
                window.getSelection().empty();
                var range = document.createRange();
                range.selectNode(document.getElementById(code));
                window.getSelection().addRange(range);
                document.execCommand("copy");
                this.success = true;
            }
        },
    }
</script>

<style scoped>

</style>